import { useHeadTitle } from './useHeadTitle'
import type { Page } from '~/types/api/page'
import type { Tag } from '~/types/api/tag'
import type { PreloadDefinition } from '~/types/app'

export function usePageHead({ pageContent, addAlternate = false, addSearchParams = [], preloads = [] }: { pageContent: Ref<Page | Tag | null>, addAlternate?: boolean, addSearchParams?: string[], preloads?: PreloadDefinition[] }) {
  return {
    title: useHeadTitle({ pageContent }),
    htmlAttrs: useHeadHtmlAttrs(),
    link: useHeadLink({ addAlternate, addSearchParams, preloads }),
    meta: useHeadMeta({ pageContent }),
  }
}
